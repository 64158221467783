/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Label,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { CheckmarkCircle48Filled, Copy16Regular, Info16Regular, MoreVertical16Regular } from '@fluentui/react-icons';
import React from 'react';
import { AuthorRoles, IChatMessage } from '../../../libs/models/ChatMessage';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import { useDialogClasses } from '../../../styles';
import { TokenUsageGraph } from '../../token-usage/TokenUsageGraph';

interface IPromptDialogProps {
    message: IChatMessage;
}

const useClasses = makeStyles({
    buttonContextMenu: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    inputCopyText: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '1px',
        opacity: 0,
    },
    textCopiedDialog: {
        textAlign: 'center',
        maxWidth: '200px',
    },
    checkmarkIcon: {
        color: tokens.colorBrandForeground2,
    },
});

export const ChatContextMenu: React.FC<IPromptDialogProps> = ({ message }) => {
    const { features } = useAppSelector((state: RootState) => state.app);
    const dialogClasses = useDialogClasses();
    const [promptDialog, showPromptDialog] = React.useState(false);
    const [copyingText, setCopyingText] = React.useState(false);
    const elementInputHidden = React.createRef<HTMLInputElement>();
    const classes = useClasses();

    const copyMessageToClipboard = (messageContentHTML?: string, messageContentPlain?: string) => {
        function listener(e: any) {
            e.clipboardData.setData('text/html', messageContentHTML);
            e.clipboardData.setData('text/plain', messageContentPlain);
            e.preventDefault();
        }

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    };

    React.useEffect(() => {
        if (copyingText && elementInputHidden.current && message.id) {
            const messageContent = document
                .getElementById(`chat-history-item-${message.id}`)
                ?.innerHTML.replaceAll('<input ', '<inputhidden');

            copyMessageToClipboard(messageContent, message.content);

            setTimeout(() => {
                setCopyingText(false);
            }, 1000);
        }
    }, [copyingText, elementInputHidden, message]);

    return (
        <>
            <Dialog
                open={promptDialog}
                onOpenChange={(_event, data) => {
                    showPromptDialog(data.open);
                }}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Prompt</DialogTitle>
                        <DialogContent>
                            <TokenUsageGraph promptView tokenUsage={message.tokenUsage ?? {}} />
                            {message.prompt
                                ?.split('\n')
                                .map((paragraph, idx) => <p key={`prompt-details-${idx}`}>{paragraph}</p>)}
                        </DialogContent>
                        <DialogActions position="start" className={dialogClasses.footer}>
                            <Label size="small" color="brand">
                                Want to learn more about prompts? Click{' '}
                                <Link href="https://aka.ms/sk-about-prompts" target="_blank" rel="noreferrer">
                                    here
                                </Link>
                                .
                            </Label>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Close</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog
                open={copyingText}
                onOpenChange={(_event, data) => {
                    setCopyingText(data.open);
                }}
            >
                <DialogSurface className={classes.textCopiedDialog}>
                    <DialogBody>
                        <DialogContent>
                            <CheckmarkCircle48Filled className={classes.checkmarkIcon} />
                            <p>Text copied to clipboard</p>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        icon={<MoreVertical16Regular />}
                        size="small"
                        appearance="transparent"
                        className={classes.buttonContextMenu}
                    />
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            icon={<Copy16Regular />}
                            onClick={() => {
                                setCopyingText(true);
                            }}
                        >
                            Copy to clipboard
                        </MenuItem>
                        {features[FeatureKeys.ShowPrompt].enabled && message.authorRole === AuthorRoles.Bot && (
                            <MenuItem
                                icon={<Info16Regular />}
                                onClick={() => {
                                    showPromptDialog(true);
                                }}
                            >
                                Prompt information
                            </MenuItem>
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>

            <input type="text" ref={elementInputHidden} className={classes.inputCopyText} />
        </>
    );
};
