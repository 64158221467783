// A component to show 6 buttons containing some sample questions
// to guide the user through the chatbot conversation.

import React from 'react';
import { CompoundButton, Divider, makeStyles, shorthands, tokens, Title2, Subtitle2 } from '@fluentui/react-components';
import { customTokens } from '../../styles';
import debug from 'debug';
import { Constants } from '../../Constants';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { BotSparkle24Regular, Search24Regular, DocumentAdd24Regular } from '@fluentui/react-icons';
import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { ChatScope } from '../../redux/features/conversations/ChatState';
import { IAppConfigResult, useAppConfig } from '../../libs/hooks/useAppConfig';
import { IAppConfigValue } from '../../libs/services/AppConfigService';

const log = debug(Constants.debug.root).extend('chat-guidance');

const useClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    titles: {
        textAlign: 'center',
    },
    guidanceButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalM),
        maxWidth: '900px',
        width: '100%',
        justifySelf: 'center',
    },
    guidanceButton: {
        ...shorthands.margin('2px', 0),
        flexBasis: '49%',
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'left',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        boxShadow: tokens.shadow2,
        '&:hover': {
            boxShadow: tokens.shadow4,
            backgroundColor: tokens.colorNeutralBackground3Hover
        },
        minHeight: '80px',
        '& .fui-CompoundButton__contentContainer': {
            width: '100%',
        },
    },
    divider: {
        paddingTop: tokens.spacingVerticalM,
    }
});

interface IChatGuidanceProps {
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

interface IChatGuidance {
    scope: ChatScope;
    header: string;
    text: string;
}

export const ChatGuidance: React.FC<IChatGuidanceProps> = ({ onSubmit }) => {
    const [guidances, setGuidances] = React.useState<IChatGuidance[]>([]);
    const classes = useClasses();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = `${emailAddress}`;

    const appConfig = useAppConfig();

    // TODO: This needs to happen only once and not for every chat
    const getSplashGuidanceContent = async () => {
        const response: IAppConfigResult<IAppConfigValue[]> = await appConfig.getConfigMultipleValues(
            'Splash Guidance',
            user,
        );
        if (response.Success) {
            const values = response.Data;
            const updatedGuidances: IChatGuidance[] = [];
            values?.forEach((value) => {
                const scope = ChatScope[value.Category as keyof typeof ChatScope];
                const header = value.CategoryTitle ?? '';
                const text = value.Value;
                updatedGuidances.push({ scope, header, text });
            });
            setGuidances(updatedGuidances);
        }
    };

    // TODO: Add other scopes
    const getIconBasedOnChatScope = (chatScope: ChatScope): JSX.Element => {
        switch (chatScope) {
            case ChatScope.None:
                return <BotSparkle24Regular color={customTokens.ekfbTeal} />;
            case ChatScope.DocumentUpload:
                return <DocumentAdd24Regular color={customTokens.ekfbBlue} />;
            case ChatScope.Web:
                return <Search24Regular color={customTokens.ekfbGrey} />;
            default:
                return <BotSparkle24Regular color={customTokens.ekfbTeal} />;
        }
    }

    React.useEffect(() => {
        getSplashGuidanceContent()
            .catch((e) => {
                log('error getting splash guidance', e);
            });
    }, []);

   
    const handleSubmit = async (value: string, messageType: ChatMessageType = ChatMessageType.Message, scope: ChatScope = ChatScope.None) => {
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        // if scope is DocumentUpload the set the scope to None
        if (scope === ChatScope.DocumentUpload) {
            scope = ChatScope.None;
        }

        const chatState = conversations[selectedId];

        // if scope is different from the current scope, update the chat scope
        if (scope !== chatState.chatScope) {
            await chat.editChat(selectedId, chatState.title, chatState.systemDescription, chatState.memoryBalance, scope);
        }

        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Thomas the AI Engine is typing' }));
        onSubmit({ value, messageType, chatId: selectedId })
            .catch((error) => {
                const message = `Error submitting chat input: ${(error as Error).message}`;
                log(message);
                dispatch(
                    addAlert({
                        type: AlertType.Error,
                        message,
                    }),
                );
            });
    };

    return (
        <div className={classes.root}>
            <Title2 className={classes.titles}>Thomas the AI Engine</Title2>
            <Subtitle2 className={classes.titles}>Ask me a question or select from one of the samples below</Subtitle2>
            <div className={classes.guidanceButtonContainer}>
                {guidances.map((guidance, index) => (
                    <CompoundButton
                        title={guidance.text}
                        key={index}
                        className={classes.guidanceButton}
                        icon={getIconBasedOnChatScope(guidance.scope)}
                        secondaryContent={guidance.text}
                        onClick={() => {
                            handleSubmit(
                                guidance.text,
                                ChatMessageType.Message,
                                guidance.scope
                            )
                                .catch((error) => {
                                    const message = `Error submitting chat input: ${(error as Error).message}`;
                                    log(message);
                                    dispatch(
                                        addAlert({
                                            type: AlertType.Error,
                                            message,
                                        }),
                                    );
                                });
                        }}
                    >
                        {guidance.header}
                    </CompoundButton>
                ))}
                <Divider className={classes.divider} />
            </div>
        </div>
    );
};