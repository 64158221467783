import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Text,
    Link,
    makeStyles,
    shorthands,
    mergeClasses,
} from '@fluentui/react-components';
import { customTokens } from '../../../styles';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: customTokens.spacingVerticalS,
        backgroundColor: customTokens.colorNeutralBackground3,
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
    },
    header: {
        '> button': {
            paddingLeft: '2px',
        },
    },
    panel: {
        paddingTop: customTokens.spacingVerticalXXS,
        paddingBottom: customTokens.spacingVerticalM,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        flexWrap: 'wrap',
    },
    pillWrapper: {
        marginBottom: customTokens.spacingHorizontalS,
    },
    pill: {
        ...shorthands.borderRadius(customTokens.borderRadiusCircular),
        backgroundColor: customTokens.colorBrandBackground,
        lineHeight: customTokens.lineHeightBase100,
        ...shorthands.padding(customTokens.spacingVerticalXXS, customTokens.spacingHorizontalS),
        marginRight: customTokens.spacingHorizontalS,
        fontSize: customTokens.fontSizeBase200,
    },
    link: {
        color: customTokens.colorBrandBackgroundInverted,
        ':hover': {
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
    highlight: {
        backgroundColor: 'rgb(188, 98, 126)',
    },
    noUrl: {
        cursor: 'default',
        textDecorationLine: 'none',
        ':hover': {
            textDecorationLine: 'none',
        },
        ':active': {
            textDecorationLine: 'none',
            color: customTokens.colorBrandBackgroundInverted,
        },
    }
});

interface ICitationsProps {
    citations?: string[];
    contextCitationsIndexes?: number[];
}

export const Citations: React.FC<ICitationsProps> = ({ citations, contextCitationsIndexes }) => {
    const classes = useClasses();

    // if there are no citations, don't render anything
    if (!citations || citations.length === 0) {
        return null;
    }

    // citations is an array of strings which are links to the sources of the information
    // we need to get the domain name of each link and display it in a list
    // for example, if the citation is https://www.cdc.gov/coronavirus/2019-ncov/vaccines/fully-vaccinated.html
    // we want to display "cdc.gov" in the list with link as the href

    const citationList: JSX.Element[] = [];
    citations.forEach((citation, index) => {
        const isUploadedDocument = citation.startsWith('Document: ');

        if (isUploadedDocument) {
            const documentName = citation.replace('Document: ', '');
            const documentNameSliced = documentName.slice(0, 23);

            citationList.push(
                <span className={mergeClasses(classes.pill, classes.highlight)}>
                    <Link className={mergeClasses(classes.link, classes.noUrl)} key={index} title={documentName}>
                        {contextCitationsIndexes?.includes(index) ? `${index + 1}. ${documentNameSliced}` : documentNameSliced}...
                    </Link>
                </span>,
            );
        } else {
            const url = new URL(citation);
            const domain = url.hostname.replace('www.', '');

            if (domain.includes('sharepoint.com')) {
                const documentName = url.href.split('/').pop();

                if (!documentName) {
                    return;
                }

                const documentNameSliced = documentName.slice(0, 23);

                citationList.push(
                    <span className={classes.pill}>
                        <Link className={classes.link} href={citation} target="_blank" key={index} title={documentName}>
                            {contextCitationsIndexes?.includes(index) ? `${index + 1}. ${documentNameSliced}` : documentNameSliced}...
                        </Link>
                    </span>,
                );
            } else {
                citationList.push(
                    <span className={classes.pill}>
                        <Link className={classes.link} href={citation} target="_blank" key={index}>
                            {domain}
                        </Link>
                    </span>,
                );
            }
        }
    });

    return (
        <div className={classes.root}>
            <Accordion collapsible>
                <AccordionItem value="">
                    <AccordionHeader className={classes.header}>
                        <Text size={200} weight="bold">
                            Response citations*
                        </Text>
                    </AccordionHeader>
                    <AccordionPanel className={classes.panel}>
                        <Text size={200}>Here is a list of citations used to generate the response above:</Text>
                        <div className={classes.linksContainer}>
                            {citationList.map((citation, index) => (
                                <div key={index} className={classes.pillWrapper}>
                                    {citation}
                                </div>
                            ))}
                        </div>
                        <Text size={200} italic>
                            * This response was generated using a combination of internet sources and Azure ChatGPT.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};
