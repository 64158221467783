/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class CacheUtils {
    public static readonly dataCacheExpiresMin: number = 120;
    public static readonly dataCacheShortExpiresMin: number = 3;
    public static readonly dataCacheLongExpiresMin: number = 480;
    public static readonly dataCacheVeryLongExpiresMin: number = 3600;

    public static buildStorageKey(key: string) {
        const prefix = 'candc';
        return `${prefix}_${key}`.replace(/[^a-zA-Z0-9]/g, '.');
    }

    public static clearStoredDataByKey(key: string): void {
        const newKey: string = CacheUtils.buildStorageKey(key);
        sessionStorage.removeItem(newKey);
    }

    public static clearStoredDataStartingByKey(key: string): void {
        const startingKey: string = CacheUtils.buildStorageKey(key);

        let n = sessionStorage.length;

        while (n--) {
            const k = sessionStorage.key(n) ?? '';
            if (k.startsWith(startingKey)) {
                sessionStorage.removeItem(k);
            }
        }
    }

    public static clearStoredDataContainsKey(keyPrefix: string): void {
        const newKey: string = CacheUtils.buildStorageKey(keyPrefix);

        let n = sessionStorage.length;

        while (n--) {
            const k = sessionStorage.key(n) ?? '';
            if (k.toLowerCase().includes(newKey.toLowerCase())) {
                sessionStorage.removeItem(k);
            }
        }
    }

    public static getStoredDataByKey(key: string, useLocalStorage?: boolean): any {
        let returnData: any = null;

        const newKey: string = CacheUtils.buildStorageKey(key);
        const localCache: any = useLocalStorage
            ? window.localStorage.getItem(newKey)
            : window.sessionStorage.getItem(newKey);

        if (localCache !== null) {
            const nowDt = new Date();
            const cachedData = JSON.parse(localCache);
            if (useLocalStorage ?? cachedData.expiryTime > nowDt) {
                returnData = cachedData.data;
            }
        }

        return returnData;
    }

    public static setStoredDataByKey(
        key: string,
        dataToStore: any,
        expireMinutes?: number,
        useLocalStorage?: boolean,
    ): void {
        const newKey: string = CacheUtils.buildStorageKey(key);
        const nowDt = new Date();
        const expiryTime = useLocalStorage ? null : nowDt.setMinutes(nowDt.getMinutes() + (expireMinutes ?? CacheUtils.dataCacheExpiresMin));
        const data: any = { data: dataToStore, expiryTime };

        if (useLocalStorage) {
            try {
                window.localStorage.setItem(newKey, JSON.stringify(data));
            } catch (e: any) {
                if (e.code === 22) {
                    // Storage full, maybe notify user or do some clean-up
                }
            }
        } else {
            try {
                window.sessionStorage.setItem(newKey, JSON.stringify(data));
            } catch (e: any) {
                if (e.code === 22) {
                    // Storage full, maybe notify user or do some clean-up
                }
            }
        }
    }
}
